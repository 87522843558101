import { useQuery } from '@tanstack/react-query';
import queryTypes from '../types/hookTypes';
import getInvalidVatRgsCodes from '../services/api/transactions/getInvalidVatRgsCodes';

const useHook = () => {
  return useQuery<string[]>(
    [queryTypes.invalidVatRgsCodes],
    getInvalidVatRgsCodes,
  );
};

export default useHook;
