import InfoIcon from '@mui/icons-material/Info';
import { Alert } from '@mui/joy';
import Stack from '@mui/joy/Stack';
import { DivisionSelect } from '../../components/divisionSelect';
import { PageHeader } from '../../components/pageHeader';
import { YearSelect } from '../../components/yearSelect';
import useFilterStore from '../../stores/filter';
import { VatRoundingDashboardTable } from './table';

export const VatRoundingDashboard = () => {
  const { selectedDivision, year } = useFilterStore();

  return (
    <Stack direction="column" gap={3}>
      <PageHeader>Btw rondrekening</PageHeader>
      <Alert variant="soft" color="primary" startDecorator={<InfoIcon />}>
        Dit dashboard is in ontwikkeling.
      </Alert>

      <Stack direction={'row'} justifyContent={'space-between'}>
        <Stack direction={'row'} gap={3}>
          <DivisionSelect />
          <YearSelect />
        </Stack>
      </Stack>
      {selectedDivision ? <VatRoundingDashboardTable administrationId={selectedDivision} year={year} /> : <p>Selecteer een administratie</p>}
    </Stack>
  );
};
