export type VatRoundingGroup = {
  key: string;
  label: string;
  rgsCodes: string[];
  fuzzyMatch: boolean;
};

export const vatRoundingDashboardGroups: VatRoundingGroup[] = [
  {
    key: '1a',
    label: 'Leveringen/diensten belast met 21%',
    rgsCodes: ['WOmzNopOlh', 'WOmzNohOlh', 'WOmzNodOdh'],
    fuzzyMatch: false,
  },
  {
    key: '1b',
    label: 'Leveringen/diensten belast met 9%',
    rgsCodes: ['WOmzNopOlv', 'WOmzNohOlv', 'WOmzNodOdl'],
    fuzzyMatch: false,
  },
  {
    key: '1c',
    label: 'Leveringen/diensten belast met overige tarieven behalve 0%',
    rgsCodes: ['WOmzNopOlo', 'WOmzNohOlo', 'WOmzNodOdo'],
    fuzzyMatch: false,
  },
  {
    key: '1d',
    label: 'Privé gebruik',
    rgsCodes: ['WOmzNopOpg', 'WOmzNohOpg', 'WOmzNodOpd'],
    fuzzyMatch: false,
  },
  {
    key: '1e',
    label: 'Leveringen/diensten belast met 0% of niet bij je belast',
    rgsCodes: ['WOmzNopOlg', 'WOmzNohOlg', 'WOmzNodOdg'],
    fuzzyMatch: false,
  },
  {
    key: '2a',
    label: 'Leveringen/diensten waarbij de heffing van omzetbelasting naar je is verlegd',
    rgsCodes: ['WOmzNopOll', 'WOmzNohOll', 'WOmzNodOdv'],
    fuzzyMatch: false,
  },
  {
    key: '3a',
    label: 'Leveringen naar landen buiten de EU (uitvoer)',
    rgsCodes: ['WOmzNopOln', 'WOmzNohOln', 'WOmzNodOdb'],
    fuzzyMatch: false,
  },
  {
    key: '3b',
    label: 'Leveringen naar/diensten in landen binnen de EU',
    rgsCodes: ['WOmzNopOli', 'WOmzNohOli', 'WOmzNodOdi'],
    fuzzyMatch: false,
  },
  {
    key: '3c',
    label: 'Installatie/afstandsverkopen binnen de EU',
    rgsCodes: ['WOmzNopOla', 'WOmzNohOla', 'WOmzNodOda'],
    fuzzyMatch: false,
  },
  {
    key: '4a',
    label: 'Leveringen/diensten uit landen buiten de EU (invoer)',
    rgsCodes: ['WOmzNopOlu', 'WOmzNohOlu', 'WOmzNodOdu'],
    fuzzyMatch: false,
  },
  {
    key: '4b',
    label: 'Leveringen/diensten uit landen binnen de EU',
    rgsCodes: ['WOmzNopOle', 'WOmzNohOle', 'WOmzNodOde'],
    fuzzyMatch: false,
  },
  {
    key: '5a',
    label: 'Verschuldigde omzetbelasting',
    rgsCodes: [
      'WOmzNopOlh',
      'WOmzNohOlh',
      'WOmzNodOdh',
      'WOmzNopOlv',
      'WOmzNohOlv',
      'WOmzNodOdl',
      'WOmzNopOlo',
      'WOmzNohOlo',
      'WOmzNodOdo',
      'WOmzNopOpg',
      'WOmzNohOpg',
      'WOmzNodOpd',
      'WOmzNopOlg',
      'WOmzNohOlg',
      'WOmzNodOdg',
      'WOmzNopOll',
      'WOmzNohOll',
      'WOmzNodOdv',
      'WOmzNopOln',
      'WOmzNohOln',
      'WOmzNodOdb',
      'WOmzNopOli',
      'WOmzNohOli',
      'WOmzNodOdi',
      'WOmzNopOla',
      'WOmzNohOla',
      'WOmzNodOda',
      'WOmzNopOlu',
      'WOmzNohOlu',
      'WOmzNodOdu',
      'WOmzNopOle',
      'WOmzNohOle',
      'WOmzNodOde',
    ],
    fuzzyMatch: false,
  },
  {
    key: '5b',
    label: 'Voorbelasting',
    rgsCodes: ['WOok', 'WKol', 'WKpr', 'WPer', 'WAfs', 'WWvi', 'WBwv', 'WBed', 'WWfa', 'WBel'],
    fuzzyMatch: true,
  },
  {
    key: '-',
    label: 'Te vorderen',
    rgsCodes: [
      'WOok',
      'WKol',
      'WKpr',
      'WPer',
      'WAfs',
      'WWvi',
      'WBwv',
      'WBed',
      'WWfa',
      'WBel',
      'WOmzNopOlh',
      'WOmzNohOlh',
      'WOmzNodOdh',
      'WOmzNopOlv',
      'WOmzNohOlv',
      'WOmzNodOdl',
      'WOmzNopOlo',
      'WOmzNohOlo',
      'WOmzNodOdo',
      'WOmzNopOpg',
      'WOmzNohOpg',
      'WOmzNodOpd',
      'WOmzNopOlg',
      'WOmzNohOlg',
      'WOmzNodOdg',
      'WOmzNopOll',
      'WOmzNohOll',
      'WOmzNodOdv',
      'WOmzNopOln',
      'WOmzNohOln',
      'WOmzNodOdb',
      'WOmzNopOli',
      'WOmzNohOli',
      'WOmzNodOdi',
      'WOmzNopOla',
      'WOmzNohOla',
      'WOmzNodOda',
      'WOmzNopOlu',
      'WOmzNohOlu',
      'WOmzNodOdu',
      'WOmzNopOle',
      'WOmzNohOle',
      'WOmzNodOde',
    ],
    fuzzyMatch: true,
  },
];
