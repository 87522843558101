import 'react-circular-progressbar/dist/styles.css';
import { GetVatRoundingDashboardRow } from '../../services/api/dashboards/getVatRoundingDashboard';
import { formatter } from '../../translations/numberFormatter';

export const getAndFormatRgsTotal = (data: GetVatRoundingDashboardRow[], rgsCodes: string[], quarter: number) => {
  const values = data.reduce((acc, row) => {
    if (rgsCodes.includes(row.rgsCode)) {
      acc += row.quarters[quarter - 1];
    }
    return acc;
  }, 0);

  return formatter.format(values);
};

export const getAndFormatAmount = (data: GetVatRoundingDashboardRow[], rgsCode: string, quarter: number) => {
  const value = data.find((row) => row.rgsCode === rgsCode)?.quarters[quarter - 1];
  if (!value) {
    return '-';
  }

  return formatter.format(value);
};

export const getRgsTotalFuzzy = (data: GetVatRoundingDashboardRow[], rgsCodes: string[], quarter: number) => {
  const values = data.reduce((acc, row) => {
    const hasSamePrefix = rgsCodes.some((code) => row.rgsCode.startsWith(code));
    if (hasSamePrefix) {
      acc += row.quarters[quarter - 1];
    }
    return acc;
  }, 0);

  return values;
};

export const getAndFormatRgsTotalFuzzy = (data: GetVatRoundingDashboardRow[], rgsCodes: string[], quarter: number) => {
  const values = data.reduce((acc, row) => {
    const hasSamePrefix = rgsCodes.some((code) => row.rgsCode.startsWith(code));
    if (hasSamePrefix) {
      acc += row.quarters[quarter - 1];
    }
    return acc;
  }, 0);

  return formatter.format(values);
};

export const quarters = [1, 2, 3, 4];
