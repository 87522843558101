import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { IconButton, Tooltip, Typography } from '@mui/joy';
import { useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { getRgsCode } from '../../assets/rgsCodes';
import { GetVatRoundingDashboardRow } from '../../services/api/dashboards/getVatRoundingDashboard';
import { VatRoundingGroup } from './tableConfig';
import { getAndFormatAmount, getAndFormatRgsTotal, getAndFormatRgsTotalFuzzy, quarters } from './util';

type VatRoundingDashboardTableProps = {
  group: VatRoundingGroup;
  data: GetVatRoundingDashboardRow[];
  year: number;
  onClick: (rgsCode: string, year: number, quarter: number) => void;
};

export const VatRoundingDashboardTableRow = (props: VatRoundingDashboardTableProps) => {
  const [open, setOpen] = useState(false);
  const { data, group, onClick, year } = props;

  return (
    <>
      <tr
        onClick={() => {
          setOpen(!open);
        }}
      >
        <td>
          <IconButton aria-label="expand row" variant="plain" color="neutral" size="sm">
            {open ? <ChevronUpIcon className="w-5" /> : <ChevronDownIcon className="w-5" />}
          </IconButton>
        </td>
        <td>{group.key}</td>

        {quarters.map((quarter) => {
          // Exception for 5b and -, since we have lvl 2 codes that need to be matched, and the data has lvl 4 codes
          if (group.key === '5b' || group.key === '-') {
            const color = getAndFormatRgsTotalFuzzy(data, group.rgsCodes, quarter).includes('-') ? 'red' : 'black';
            return (
              <td
                style={{
                  color,
                }}
                key={quarter}
              >
                {getAndFormatRgsTotalFuzzy(data, group.rgsCodes, quarter)}
              </td>
            );
          }

          return <td key={quarter}>{getAndFormatRgsTotal(data, group.rgsCodes, quarter)}</td>;
        })}
      </tr>
      {open && (
        <>
          <tr>
            <td
              colSpan={6}
              style={{
                paddingLeft: '1.9rem',
              }}
            >
              {group.label}
            </td>
          </tr>
          {group.rgsCodes.map((rgsCode) => {
            const commonStyle = { fontSize: 'smaller' };
            return (
              <tr key={rgsCode}>
                <td colSpan={2} style={{ textAlign: 'center', ...commonStyle }}>
                  <Tooltip title={getRgsCode(rgsCode)?.descriptionLong}>
                    <Typography>{rgsCode}</Typography>
                  </Tooltip>
                </td>
                {quarters.map((quarter) => {
                  return (
                    <td onClick={() => onClick(rgsCode, year, quarter)} key={quarter}>
                      {getAndFormatAmount(data, rgsCode, quarter)}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </>
      )}
    </>
  );
};
