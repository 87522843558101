import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import InfoIcon from '@mui/icons-material/Info';
import { Alert, Button, IconButton, Modal, ModalDialog } from '@mui/joy';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import ModalClose from '@mui/joy/ModalClose';
import Sheet from '@mui/joy/Sheet';
import Stack from '@mui/joy/Stack';
import Table from '@mui/joy/Table';
import Typography from '@mui/joy/Typography';
import { useState } from 'react';
import { getRgsCode } from '../../../assets/rgsCodes';
import { RgsFilters, RgsSelect } from '../../../components/rgsSelectv2';
import useInvalidVatRgsCodes from '../../../hooks/useInvalidVatRgsCodes';
import createInvalidVatRgsCode from '../../../services/api/transactions/createInvalidVatRgsCode';
import deleteInvalidVatRgsCode from '../../../services/api/transactions/deleteInvalidVatRgsCode';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';

export type Check = {
  ID: string;
  RgsCodes: string;
};

export default function RgsCodesModal() {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rgsCode, setRgsCode] = useState<string>('');
  const invalidVatRgsCodesQuery = useInvalidVatRgsCodes();

  const createRgsCode = async () => {
    try {
      setLoading(true);
      await createInvalidVatRgsCode(rgsCode);
      await invalidVatRgsCodesQuery.refetch();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteRgsCode = async (rgsCode: string) => {
    try {
      setLoading(true);
      await deleteInvalidVatRgsCode(rgsCode);
      await invalidVatRgsCodesQuery.refetch();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const rows = invalidVatRgsCodesQuery.data || [];

  return (
    <Stack gap={3} direction={'row'}>
      <FormControl>
        <FormLabel>Instellingen</FormLabel>
        <Button startDecorator={<SettingsRoundedIcon />} onClick={() => setIsOpen(true)}>
          Configureer
        </Button>
        <Modal
          aria-labelledby="close-modal-title"
          open={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ModalDialog size="md">
            <ModalClose variant="plain" sx={{ m: 1 }} />
            <Stack direction={'column'} gap={3}>
              <Stack gap={1}>
                <Typography component="h2" id="close-modal-title" level="h4" textColor="inherit" fontWeight="lg">
                  Rgs codes configureren voor de BTW attentiepunten
                </Typography>
                <Alert variant="soft" color="primary" startDecorator={<InfoIcon />}>
                  Wordt toegepast op alle administraties!
                </Alert>
              </Stack>

              <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <RgsSelect
                  onChange={(rgsCode) => {
                    setRgsCode(rgsCode);
                  }}
                  disabled={loading}
                  value={rgsCode}
                  type={RgsFilters.RGS4}
                />
                <Button startDecorator={<AddRoundedIcon />} onClick={createRgsCode} loading={loading}>
                  Toevoegen
                </Button>
              </Stack>

              <Stack direction={'column'} gap={3}>
                <Sheet
                  className="OrderTableContainer"
                  variant="outlined"
                  sx={{
                    width: '100%',
                    borderRadius: 'sm',
                    flexShrink: 1,
                    overflow: 'auto',
                    minHeight: 0,
                    maxHeight: '80vh',
                  }}
                >
                  <Table
                    stickyHeader
                    stickyFooter
                    hoverRow
                    sx={{
                      '--TableCell-height': '10px',
                      '& tr > *:last-child': {
                        textAlign: 'right',
                        paddingLeft: '1rem',
                      },
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Beschrijving</th>
                        <th>RGS Code toevoegen aan BTW attentiepunten check</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {rows.map((row: any) => {
                        return (
                          <tr key={row}>
                            <td>{getRgsCode(row)?.descriptionShort ?? ''}</td>
                            <td>{row}</td>
                            <td>
                              <IconButton
                                onClick={async () => {
                                  await deleteRgsCode(row);
                                }}
                                disabled={loading}
                              >
                                <DeleteRoundedIcon />
                              </IconButton>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Sheet>
              </Stack>
            </Stack>
          </ModalDialog>
        </Modal>
      </FormControl>
    </Stack>
  );
}
