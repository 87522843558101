import { Box, CircularProgress } from '@mui/joy';
import Sheet from '@mui/joy/Sheet';
import Table from '@mui/joy/Table';
import { useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { useVatRoundingDashboard } from '../../hooks/useVatRoundingDashboard';
import { vatRoundingDashboardGroups } from './tableConfig';
import { VatRoundingDashboardTableRow } from './tableRow';
import { TransactionModal } from './transactionModal';

type VatRoundingDashboardTableProps = {
  administrationId: string;
  year: number;
};

export const VatRoundingDashboardTable = (props: VatRoundingDashboardTableProps) => {
  const { administrationId, year } = props;
  const vatRoundingDashboardQuery = useVatRoundingDashboard({
    year,
    administrationId,
  });
  const [transactionModalOpen, setTransactionModalOpen] = useState(false);
  const [selectedRgsCode, setSelectedRgsCode] = useState<string | null>(null);
  const [selectedQuarter, setSelectedQuarter] = useState<number>(1);

  const data = vatRoundingDashboardQuery.data || [];

  if (vatRoundingDashboardQuery.isLoading) {
    return (
      <Box sx={{ margin: 'auto', marginTop: '10vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  const modalStartDate = `${year}-${(selectedQuarter - 1) * 3 + 1}-01`;
  const modalEndDate = `${year}-${selectedQuarter * 3}-31`;

  return (
    <>
      {selectedRgsCode && (
        <TransactionModal
          administrationId={administrationId}
          startDate={modalStartDate}
          endDate={modalEndDate}
          open={transactionModalOpen}
          rgsCode={selectedRgsCode}
          setOpen={setTransactionModalOpen}
        />
      )}
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          width: '100%',
          borderRadius: 'sm',
          flexShrink: 1,
          overflow: 'auto',
        }}
      >
        <Table
          aria-label="basic table"
          stickyHeader
          stickyFooter
          hoverRow
          sx={{
            '--TableCell-height': '3rem',
            '& tr': {
              '&:hover': {
                cursor: 'pointer',
              },
            },
          }}
        >
          <thead>
            <tr>
              <td style={{ width: '5%' }} />
              <th
                style={{
                  width: '7%',
                }}
              >
                Code
              </th>
              <th>BTW Q1</th>
              <th>BTW Q2</th>
              <th>BTW Q3</th>
              <th>BTW Q4</th>
            </tr>
          </thead>
          <tbody>
            {vatRoundingDashboardGroups.map((group) => {
              return (
                <VatRoundingDashboardTableRow
                  key={group.key}
                  data={data}
                  year={year}
                  group={group}
                  onClick={(rgsCode: string, year: number, quarter: number) => {
                    setSelectedQuarter(quarter);
                    setSelectedRgsCode(rgsCode);
                    setTransactionModalOpen(true);
                  }}
                />
              );
            })}
          </tbody>
        </Table>
      </Sheet>
    </>
  );
};
