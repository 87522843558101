enum types {
  KiaAdvice = 'KiaAdvice',
  KiaLegibleTransactions = 'KiaLegibleTransactions',
  AdminDomains = 'AdminDomains',
  getUsers = 'getUsers',
  completeness = 'completeness',
  pipelineRuns = 'pipelineRuns',
  completenessByDivision = 'completenessByDivision',
  recommendationsByDivision = 'recommendationsByDivision',
  divisions = 'divisions',
  division = 'division',
  completenessTransactions = 'completenessTransactions',
  outstandingReceivables = 'outstandingReceivables',
  qualityChecks = 'qualityChecks',
  qualityCheckConfiguration = 'qualityCheckConfiguration',
  exactAuth = 'exactAuth',
  exactLoadDetails = 'exactLoadDetails',
  tableMonitoring = 'tableMonitoring',
  kpi = 'kpi',
  userDomains = 'userDomains',
  me = 'me',
  profitLossPerformance = 'profitLossPerformance',
  assignedAdministrations = 'assignedAdministrations',
  glAccountEfficiency = 'glAccountEfficiency',
  insightOverview = 'insightOverview',
  insightsDrilldown = 'insightsDrilldown',
  insightChecks = 'insightChecks',
  domainInvites = 'domainInvites',
  rgsBalance = 'rgsBalance',
  profitLossPeriodTotal = 'profitLossPeriodTotal',
  kenGetal = 'kenGetal',
  rgsBalanceTimeseries = 'rgsBalanceTimeseries',
  balanceChecks = 'balanceChecks',
  kpiChecks = 'kpiChecks',
  balanceChecksByAdministration = 'balanceChecksByAdministration',
  kpiChecksByAdministration = 'kpiChecksByAdministration',
  glAccountMappingOverview = 'glAccountMappingOverview',
  glAccountMappingDrilldown = 'glAccountMappingDrilldown',
  transactions = 'transactions',
  notes = 'notes',
  invalidVatDashboard = 'invalidVatDashboard',
  invalidVatTransactions = 'invalidVatTransactions',
  invalidVatRgsCodes = 'invalidVatRgsCodes',
  insightBalanceDrilldown = 'insightBalanceDrilldown',
  paymentTermAutomation = 'paymentTermAutomation',
  accounts = 'accounts',
  qualityCheckOverrides = 'qualityCheckOverrides',
  tasks = 'tasks',
  getEOLIntegrationTaskOverview = 'getEOLIntegrationTaskOverview',
  vatRoundingDashboard = 'vatRoundingDashboard',
}

export default types;
