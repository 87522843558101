import client from '../../../utils/axiosClient';

export type GetVatRoundingDashboardRow = {
  rgsCode: string;
  year: number;
  quarters: [number, number, number, number];
};

export type GetVatRoundingDashboardConfig = {
  administrationId: string;
  year: number;
};

export const getVatRoundingDashboard = async (config: GetVatRoundingDashboardConfig) => {
  const response = await client.get<GetVatRoundingDashboardRow[]>(`/api/vatRoundingDashboard/${config.administrationId}`, {
    params: {
      year: config.year,
    },
  });

  return response.data;
};
