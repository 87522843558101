import { useQuery } from '@tanstack/react-query';
import { getTransactions } from '../../services/api/transactions/getTransactions';
import queryTypes from '../../types/hookTypes';

export const useTransactions = (config: {
  administrationId?: string;
  startDate?: string;
  endDate?: string;
  offset?: number;
  limit?: number;
  minAmount?: number;
  rgsCodes?: string[];
}) => {
  return useQuery([queryTypes.transactions, ...Object.values(config)], async () => {
    return getTransactions(config);
  });
};
