import InfoIcon from '@mui/icons-material/Info';
import { Alert } from '@mui/joy';
import Stack from '@mui/joy/Stack';
import DivisionSearch from '../../components/divisionSearch';
import { PageHeader } from '../../components/pageHeader';
import { RangeSelect } from '../../components/rangeSelect';
import { UserSelect } from '../../components/userSelect';
import useFilterStore from '../../stores/filter';
import { InvalidVatTable } from './table';
import { AccountSelect } from '../../components/accountSelect';
import RgsCodesModal from './modal';

export const InvalidVatDashboard = () => {
  const { range, divisionSearch, user, account } = useFilterStore();

  return (
    <Stack direction="column" gap={3}>
      <PageHeader>Btw Dashboard</PageHeader>
      <Alert variant="soft" color="primary" startDecorator={<InfoIcon />}>
        Dit dashboard is in ontwikkeling. Dit dashboard geeft inzicht in periodes waarin btw bedragen zijn geboekt op grootboekrekeningen waar geen btw
        geboekt mag worden. Je kunt nu zelf de RGS codes voor BTW attentiepunten instellen.
      </Alert>

      <Stack direction={'row'} justifyContent={'space-between'}>
        <Stack direction={'row'} gap={3}>
          <DivisionSearch />
          <UserSelect />
          <AccountSelect />
          <RangeSelect />
        </Stack>
        <RgsCodesModal />
      </Stack>
      <InvalidVatTable
        yearStart={range.yearStart}
        yearEnd={range.yearEnd}
        monthStart={range.monthStart}
        monthEnd={range.monthEnd}
        divisionSearch={divisionSearch}
        accountID={account}
        user={user}
      />
    </Stack>
  );
};
